import React from 'react';
import 'assets/styles/pages/sidebar.scss';
import Logo from 'assets/images/logo/forest-logo.png';
import { Navbar, Nav } from 'react-bootstrap';
import { Link, navigate } from 'gatsby';
import adminprofileicon from 'assets/images/admin/admin-user-icon.png';
import bellicon from 'assets/images/admin/bell-icon.png';
import logouticon from 'assets/images/admin/logout-icon.png';
import dashboardicon from 'assets/images/admin/dashboard-icon.svg';
import projecticon from 'assets/images/admin/projecticon.svg';
import plusicon from 'assets/images/admin/plus-icon.svg';
import Initiativeeditview from 'components/admin/initiative-edit-view';
import { doAdminLogout } from './login';

//import tirupournursery from 'assets/images/register-plantation/tirupour-nursery.png';

export default function Initiativeedit() {

  const storageInfo = (typeof window !== 'undefined' && window.localStorage) ? JSON.parse(localStorage.getItem("hfnAuthUserDetailsAdmin")) : null;
  const profileName = (storageInfo && storageInfo.srcmProfile !== null) ? storageInfo.srcmProfile.name : ""

  const signOut = () => {
    doAdminLogout()
    navigate("/admin")
  }
  return (
    <>
      <div className='sidebaritem'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-2 sidebar'>
              <div className='bg-green'>
                <Navbar.Brand>
                  <Link to='/'>
                    <img className='min-logo' src={Logo} alt='Logo' />
                  </Link>
                </Navbar.Brand>
              </div>
              <Navbar.Toggle aria-controls='navbarScroll' />

              <Nav className='mr-auto my-2 my-lg-0'>
                <Nav.Link href='/' className='scroller'>
                  <img src={dashboardicon} alt='Dashboardicon' /> Dashboard
                </Nav.Link>
                <Nav.Link href='/' className='scroller green'>
                  <img src={projecticon} alt='Dashboardicon' /> Projects
                </Nav.Link>
                <Nav.Link href='/' className='scroller'>
                  <img src={plusicon} alt='Dashboardicon' /> Plantations
                </Nav.Link>
              </Nav>
            </div>
            <div className='col-md-10 sidecontent'>
              <div className='admin-initiativeviewspecies'>
                <div className='admin-dashboard-tile-bar'>
                  <div className='dashboard-title'>
                    <h3 className='title-name'> Admin Dashboard</h3>
                  </div>
                  <div className='profile-icon-admin'>
                    <div className='admin-profile-icon'>
                      <span>
                        <img src={adminprofileicon} alt='profileicon' />
                      </span>
                      <h5>{(storageInfo && storageInfo.srcmProfile !== "undefined" && storageInfo.srcmProfile !== null) ? "No Name" : profileName}</h5>
                      <p>{(storageInfo && storageInfo.srcmProfile.name !== null) ? storageInfo.srcmProfile.email : ""}</p>
                    </div>

                    <div className='visible-icon'>
                      <img className='bell-icon-symbol' src={bellicon} alt='bellicon' />
                      <img src={logouticon} onClick={signOut} alt='logouticon' />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Initiativeeditview />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
//const Sidebar = withRouter(Side);
