import React from 'react';

import AdminLayout from '../../adminlayout/index';

import SEO from 'components/seo/index';
// import Side from 'layout/sidebaritem';

import Initiativeedit from 'components/admin/initiativeedit';

const InitiativeeditPage = () => {
  return (
    <>
      <SEO title='Forests By Heartfulness admin' />
      <AdminLayout> 
      <div>
          <Initiativeedit />
        </div>
         </AdminLayout>
       
    </>
  );
};

export default InitiativeeditPage;
